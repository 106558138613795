import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

import { CustomTableActionParams } from '@app/_controls/data-table/actions/customTableActionParams';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { EmailRowDetailsComponent } from '@app/_controls/row-detail-views/email-row-details/email-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateWithTime } from '@app/_helpers/functions/date-functions';
import { EmailStatusTransformPipe } from '@app/_helpers/transform/email-status.transform';
import { EmailDto } from '@app/_models/emailDto';
import { EmailService } from '@app/_services/email.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
})
export class EmailsComponent implements OnInit, AfterViewInit, RefreshableView {
  @Input() baseFilter?: string;
  @Input() tableDivStyle?: string;
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<EmailDto, number>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    public emailService: EmailService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'from',
      header: 'Email.Columns.From',
      searchType: 'Text',
      flex: '1 1 12%',
    },
    {
      columnProperty: 'to',
      header: 'Email.Columns.To',
      searchType: 'Text',
      displayFunction: (element: string, row: EmailDto) => this.formatEmailAdresses(element),
      flex: '1 1 12%',
    },
    {
      columnProperty: 'cc',
      header: 'Email.Columns.CC',
      searchType: 'Text',
      displayFunction: (element: string, row: EmailDto) => this.formatEmailAdresses(element),
      flex: '1 1 15%',
    },
    {
      columnProperty: 'status',
      header: 'Email.Columns.Status',
      searchType: 'Selection',
      flex: '1 1 10%',
      valueMap: EmailStatusTransformPipe.statusMap,
      displayTranslate: true,
    },
    {
      columnProperty: 'subject',
      header: 'Email.Columns.Subject',
      searchType: 'Text',
      flex: '1 1 25%',
    },
    {
      columnProperty: 'errorCounter',
      compareOperator: '>=',
      searchType: 'Text',
      header: 'Email.Columns.ErrorCounter',
      flex: '1 1 6%',
    },
    {
      columnProperty: 'createDate',
      header: 'Email.Columns.CreateDate',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: EmailDto) => formatDateWithTime(element),
      flex: '1 1 10%',
    },
    {
      columnProperty: 'sendDate',
      header: 'Email.Columns.SendDate',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: EmailDto) => formatDateWithTime(element),
      flex: '1 1 10%',
    },
  ];

  private resetMultipleEmailErrorsActionParams: CustomTableActionParams = {
    customFunction: this.resetMultipleEmailErrors.bind(this),
  };

  private tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Custom,
      text: 'Admin.Emails.ResetMultipleEmailErrors',
      buttonClass: 'btn btn-success btn-sm mb-1',
      actionParams: this.resetMultipleEmailErrorsActionParams,
    },
  ];

  public tableSettings = <TableSettings<EmailDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<EmailDto, number>(this.emailService, this.errorHandler),
    dataService: this.emailService,
    tableColumns: this.tableColumns,
    rowDetailsContainerType: EmailRowDetailsComponent,
    tableDivStyle: this.tableDivStyle,
    baseColumnSort: [{ column: 'createDate', direction: 'desc' }],
    tableActions: this.tableActions,
    enableRowSelection: true,
  };

  formatEmailAdresses(element: string): string {
    if (element) {
      return element.replace(';', '\r\n');
    }

    return '';
  }

  ngOnInit(): void {
    this.tableSettings.baseFilter = this.baseFilter;
  }

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  resetMultipleEmailErrors(): void {
    let emailIds = this.dataTableComponent.rowSelection.selected.map((x) => x.id);

    if (emailIds.length == 0) {
      this.showNoEmailSelectedDialog();
      return;
    }

    this.showResetMultipleEmailErrorsConfirmationDialog(emailIds);
  }

  showNoEmailSelectedDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Admin.Emails.Errors.NoEmailsSelectedTitle',
      'Admin.Emails.Errors.NoEmailsSelectedText',
      'Global.Ok',
      undefined,
    );
  }

  showResetMultipleEmailErrorsConfirmationDialog(emailIds: string[]): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Admin.Emails.ResetMultipleEmailErrorsConfirmationDialogTitle',
      'Admin.Emails.ResetMultipleEmailErrorsConfirmationDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          this.loadingSubject.next(true);

          this.emailService.resetMultipleEmailErrors(emailIds).subscribe(
            (result) => {
              this.dataTableComponent.loadData();
              this.loadingSubject.next(false);
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }
}
