<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'User.Invoices.AddInvoice' | translate }}</h1>
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="form-group row">
    <label class="bs-label">{{ 'Global.InstitutionClinic' | translate }}</label>
    <div class="col-sm-10">
      <select class="form-control" [required]="true" formControlName="institution">
        <option *ngFor="let institution of institutions" [ngValue]="institution">
          {{ institution.title }}
        </option>
      </select>
    </div>
  </div>

  <div [hidden]="!processedInstitution">
    <div [hidden]="selectedCase || !shouldSelectCase">
      <div class="form-group row">
        <label class="bs-label">{{ 'User.Invoices.CaseSearchLabel' | translate }}</label>
        <div class="col-sm-10">
          <div
            *ngIf="
              queryRestrictions &&
                queryRestrictions.requiredIdentityProvider != null &&
                queryRestrictions.requiredIdentityProvider != queryRestrictions.actualIdentityProvider;
              else caseSearch
            ">
            <button type="button" (click)="linkRequiredIdp()" class="btn btn-primary">
              {{ 'User.Invoices.LogInWithSso' | translate: { sso: queryRestrictions.requiredIdentityProvider } }}
            </button>
            {{ 'User.Invoices.LogInWithSsoNudge' | translate }}
            <button (click)="shouldSelectCase = false" class="btn-link" type="button">
              {{ 'User.Invoices.ManualDataEntryLink' | translate }}
            </button>
          </div>
          <ng-template #caseSearch>
            <div>
              <input
                id="caseSearch"
                formControlName="caseSearch"
                type="text"
                class="form-control mr-sm-2"
                placeholder="{{ 'User.Invoices.CaseSearchPlaceholder' | translate }}" />
            </div>
            <div
              *ngIf="
                form.controls.caseSearch.invalid && (form.controls.caseSearch.dirty || form.controls.caseSearch.touched)
              "
              class="alert alert-danger">
              <div *ngIf="form.controls.caseSearch.errors?.minlength">
                {{ 'User.Invoices.Errors.MinLengthThree' | translate }}
              </div>
            </div>
            <div>
              <mat-table [dataSource]="cases$">
                <ng-container matColumnDef="caseNumber">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.CaseNumber' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ caze.caseNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="patientGivenName">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.FirstName' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ caze.patientGivenName }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="patientFamilyName">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.LastName' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ caze.patientFamilyName }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="patientBirthDate">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.DateOfBirth' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ formatDateOnly(caze.patientBirthDate) }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="patientPid">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.PID' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ caze.patientPid }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="caseStartDate">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.HospitalizationFrom' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ formatDateOnly(caze.caseStartDate) }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="caseEndDate">
                  <mat-header-cell *matHeaderCellDef>{{ 'Global.HospitalizationUntil' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let caze">{{ formatDateOnly(caze.caseEndDate) }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let caze">
                    <button type="button" class="btn-link" (click)="selectCase(caze)">
                      {{ 'User.Invoices.SetCase' | translate }}
                    </button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let caze; columns: columnsToDisplay"></mat-row>
              </mat-table>
              <div *ngIf="(cases$ | async)?.length == 0">
                <span>{{ 'User.Invoices.NoCasesFound.Text' | translate }}</span>
                <div>{{ 'User.Invoices.NoCasesFound.Reasons' | translate }}</div>
                <ul>
                  <li>{{ 'User.Invoices.NoCasesFound.SkillIssue' | translate }}</li>
                  <ng-container *ngIf="queryRestrictions as restrictions">
                    <li *ngIf="!restrictions.earliestCaseStart">
                      {{ 'User.Invoices.NoCasesFound.NoCasesAvailable' | translate }}
                    </li>
                    <li *ngIf="restrictions.earliestCaseStart">
                      {{
                        'User.Invoices.NoCasesFound.EarliestCaseStart'
                          | translate: { earliestCaseStart: formatDateOnly(asDate(restrictions.earliestCaseStart)) }
                      }}
                    </li>
                    <li *ngIf="restrictions.assignmentRanges.length > 0">
                      {{ 'User.Invoices.NoCasesFound.UserAssignments' | translate }}<br />
                      {{ formatAssignmentRanges(restrictions.assignmentRanges) }}
                    </li>
                    <li *ngIf="restrictions.externalUserRequired && restrictions.assignmentRanges.length == 0">
                      {{ 'User.Invoices.NoCasesFound.NoUserAssignment' | translate }}<br />
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div>
                {{ 'User.Invoices.ManualDataEntryHint' | translate }}
                <button (click)="shouldSelectCase = false" class="btn-link" type="button">
                  {{ 'User.Invoices.ManualDataEntryLink' | translate }}
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="selectedCase">
      <label class="bs-label"></label>
      <!-- Placeholder -->
      <div class="col-sm-8 alert column-alert-primary alert-primary">
        {{ 'User.Invoices.CaseSet' | translate: { caseNumber: selectedCase.caseNumber } }}
        <div>
          <button type="button" (click)="selectCase(undefined)" class="btn btn-primary">
            {{ 'User.Invoices.CaseReset' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div [hidden]="!selectedCase && shouldSelectCase">
      <div class="form-group row">
        <label class="bs-label">{{ 'Global.HospitalizationFrom' | translate }}</label>
        <div class="col-sm-2">
          <input
            #hospitalizationFrom
            type="text"
            formControlName="dateFrom"
            class="form-control"
            placeholder="{{ 'Global.HospitalizationFrom' | translate }}"
            bsDatepicker
            [bsConfig]="{ adaptivePosition: true }" />
          <div
            *ngIf="form.controls.dateFrom.invalid && (form.controls.dateFrom.dirty || form.controls.dateFrom.touched)"
            class="alert alert-danger">
            <div *ngIf="form.controls.dateFrom.errors?.required">
              {{ 'User.Invoices.Errors.HospitalizationFromRequired' | translate }}
            </div>
            <div *ngIf="form.controls.dateFrom.errors?.dateIsAfter">
              {{ 'User.Invoices.Errors.HospitalizationFromAfterHospitalizationUntil' | translate }}
            </div>
          </div>
        </div>
        <label class="bs-label">{{ 'Global.HospitalizationUntil' | translate }}</label>
        <div class="col-sm-2">
          <input
            type="text"
            formControlName="dateUntil"
            class="form-control"
            placeholder="{{ 'Global.HospitalizationUntil' | translate }}"
            bsDatepicker
            [bsConfig]="{ adaptivePosition: true }" />
          <div
            *ngIf="
              form.controls.dateUntil.invalid && (form.controls.dateUntil.dirty || form.controls.dateUntil.touched)
            "
            class="alert alert-danger">
            <div *ngIf="form.controls.dateUntil.errors?.required">
              {{ 'User.Invoices.Errors.HospitalizationUntilRequired' | translate }}
            </div>
            <div *ngIf="form.controls.dateUntil.errors?.dateIsBefore">
              {{ 'User.Invoices.Errors.HospitalizationUntilBeforeHospitalizationFrom' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="bs-label"></label>
        <div
          class="col-sm-10"
          [innerHTML]="'User.Invoices.AddInvoiceInfoText' | translate | safeHtml"
          routeTransformer></div>
      </div>

      <div class="form-group row">
        <label class="bs-label">{{ 'Global.Contract' | translate }}</label>
        <div class="col-sm-10">
          <select formControlName="contract" class="form-control" [compareWith]="compareContractForUserWithDetailsDto">
            <option [ngValue]="null"></option>
            <option *ngFor="let contract of contracts$ | async" [ngValue]="contract">
              {{ contract.title }}
            </option>
          </select>
          <ng-container *ngIf="!!this.form.controls.dateFrom.value">
            <div *ngIf="(contracts$ | async)?.length == 0" class="alert alert-danger">
              <div>
                {{
                  'User.Invoices.Errors.NoActiveContract'
                    | translate: { date: formatDateOnly(form.controls.dateFrom.value!) }
                }}
              </div>
            </div>
            <div *ngIf="form.controls.contract.errors?.required" class="alert alert-danger">
              <div>
                {{ 'User.Invoices.Errors.ContractRequired' | translate }}
              </div>
            </div>
          </ng-container>
          <div *ngIf="selectedCase && selectedCase.insuranceName" class="alert alert-primary">
            {{ 'User.Invoices.CaseInsurance' | translate }}: <strong>{{ selectedCase.insuranceName }}</strong>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="this.childInsurances$ | async as childInsurances"
        [hidden]="childInsurances.length == 0">
        <label class="bs-label">{{ 'Global.Insurance' | translate }}</label>
        <div class="col-sm-10">
          <select formControlName="insurance" class="form-control">
            <option [ngValue]="null"></option>
            <option *ngFor="let insurance of childInsurances" [ngValue]="insurance">
              {{ insurance.title }}
            </option>
          </select>
          <div *ngIf="form.controls.insurance.errors?.required" class="alert alert-danger">
            {{ 'User.Invoices.Errors.InsuranceRequired' | translate }}
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="!shouldSelectCase">
        <label class="bs-label">{{ 'User.Invoices.Patient' | translate }}</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'User.Invoices.CaseSearchPlaceholder' | translate }}"
            matInput
            formControlName="patientSearch"
            [matAutocomplete]="auto" />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayPatient.bind(this)">
            <mat-option *ngFor="let patient of searchedPatients$ | async" [value]="patient">
              {{ patient.firstName }} {{ patient.lastName }} {{ formatDateOnly(patient.dateOfBirth) }}
            </mat-option>
          </mat-autocomplete>
          <div
            *ngIf="
              this.form.controls.patientSearch.errors?.required ||
              this.isNoPatientSelected(this.form.controls.patientSearch.value)
            "
            class="alert alert-danger">
            {{ 'User.Invoices.Errors.PatientRequired' | translate }}
          </div>
        </div>
        <div class="col-sm-4">
          <button type="button" (click)="createPatientDialog()" class="btn btn-primary">
            {{ 'User.Invoices.NewPatient' | translate }}
          </button>
        </div>
      </div>

      <div class="form-group row" *ngIf="shouldSelectCase">
        <label class="bs-label">{{ 'User.Invoices.Patient' | translate }}</label>
        <div class="col-sm-8">
          <input disabled="disabled" class="form-control mr-sm-2" value="{{ displayPatient(selectedPatient) }}" />
          <div class="alert" *ngIf="this.preSelectedPatientIsDifferent">
            <div>
              {{
                'User.Invoices.PatientDiffersFromSelectedPatient'
                  | translate: { patientDisplay: displayPatient(this.preSelectedPatient) }
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="bs-label">{{ 'Global.InsuranceType' | translate }}</label>
        <div class="col-sm-2">
          <select formControlName="insuranceType" class="form-control">
            <option [ngValue]="null"></option>
            <option *ngFor="let insuranceType of insuranceTypeOptions$ | async" [ngValue]="insuranceType">
              {{ insuranceType.valueOf() | invoiceInsuranceTypeTransform | translate }}
            </option>
          </select>
          <div
            *ngIf="
              selectedCase &&
              form.controls.insuranceType.value != null &&
              form.controls.insuranceType.value !=
                treatmentCategoryToInvoiceInsuranceType(selectedCase.treatmentCategory)
            "
            class="alert alert-warning">
            {{ 'User.Invoices.Errors.CaseDataDifference' | translate }}
          </div>
          <ng-container *ngIf="insuranceTypeOptions$ | async as insuranceTypeOptions">
            <div
              *ngIf="insuranceTypeOptions.length > 0 && form.controls.insuranceType.errors?.required"
              class="alert alert-danger">
              {{ 'User.Invoices.Errors.InsuranceTypeRequired' | translate }}
            </div>
          </ng-container>
        </div>
        <label class="bs-label">{{ 'Dialog.EditInvoiceContainerComponent.CaseNumberOptional' | translate }}</label>
        <div class="col-sm-2">
          <input
            type="text"
            formControlName="caseNumber"
            class="form-control mr-sm-2"
            placeholder="{{ 'Global.CaseNumber' | translate }}"
            maxlength="100" />
          <div
            *ngIf="
              selectedCase &&
              form.controls.caseNumber.value != null &&
              form.controls.caseNumber.value != selectedCase.caseNumber
            "
            class="alert alert-warning">
            {{ 'User.Invoices.Errors.CaseDataDifference' | translate }}
          </div>
        </div>
        <ng-container *ngIf="this.form.controls.institution.value?.allowPidOnInvoice">
          <label class="bs-label">{{ 'Dialog.EditInvoiceContainerComponent.PIDOptional' | translate }}</label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="pid"
              class="form-control mr-sm-2"
              placeholder="{{ 'Global.PID' | translate }}"
              maxlength="100" />
            <div
              *ngIf="
                selectedCase && form.controls.pid.value != null && form.controls.pid.value != selectedCase.patientPid
              "
              class="alert alert-warning">
              {{ 'User.Invoices.Errors.CaseDataDifference' | translate }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="form-group row">
        <label class="bs-label">{{ 'Global.BESR' | translate }}</label>
        <div class="col-sm-10">
          <besr-input formControlName="besr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="bs-label">{{ 'Global.Memo' | translate }}</label>
        <div class="col-sm-10">
          <textarea
            rows="2"
            type="text"
            formControlName="memo"
            class="form-control mr-sm-2"
            placeholder="{{ 'Global.Memo' | translate }}"
            maxlength="5000"></textarea>
        </div>
      </div>
    </div>

    <button type="submit" [disabled]="this.form.invalid" class="btn btn-primary">
      {{ 'User.Invoices.AddInvoice' | translate }}
    </button>
  </div>
</form>
