import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditInsuranceContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-insurance-container/edit-insurance-container.component';
import { InsuranceDto } from '@app/_models/insuranceDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InsuranceService } from '@app/_services/insurance.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss'],
})
export class InsurancesComponent implements AfterViewInit, RefreshableView {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InsuranceDto, number>;

  constructor(
    private insuranceService: InsuranceService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'Insurance.AddInsurance',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'id',
      header: 'Global.Id',
      searchType: 'Text',
      flex: '0 0 75px',
    },
    {
      columnProperty: 'identifier',
      header: 'Global.Identifier',
      searchType: 'Text',
      flex: '0 0 160px',
    },
    {
      columnProperty: 'title',
      header: 'Global.Title',
      searchType: 'Text',
      flex: '0 0 1',
    },
    {
      columnProperty: 'maxValue',
      header: 'Insurance.MaxValueHeader',
      searchType: 'Text',
      flex: '0 0 20%',
    },
    {
      columnProperty: 'parentInsurance',
      header: 'Insurance.ParentInsurance',
      displayFunction: (parentInsurance?: InsuranceDto) => {
        return parentInsurance?.title ?? '-';
      },
      flex: '0 0 20%',
    },
  ];

  public tableSettings = <TableSettings<InsuranceDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<InsuranceDto, number>(this.insuranceService, this.errorHandler),
    dataService: this.insuranceService,
    tableColumns: this.tableColumns,
    rowActions: this.rowActions,
    tableActions: this.tableActions,
    rowEditContainer: EditInsuranceContainerComponent,
    rowEditContainerStyle: 'modal-800',
    addRowTitle: 'Insurance.AddInsurance',
    editRowTitle: 'Insurance.EditInsurance',
    deleteRowTitle: 'Insurance.DeleteInsurance',
    deleteRowText: 'Insurance.DeleteInsuranceText',
  };

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }
}
