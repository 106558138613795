import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditInfoLinkContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-info-link-container/edit-info-link-container.component';
import { BooleanTransformPipe } from '@app/_helpers/transform/boolean.transform';
import { InfoLinkLinkActionTransformPipe } from '@app/_helpers/transform/infolink-linkaction.transform';
import { InfoLinkDto } from '@app/_models/infoLinkDto';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InfoLinkService } from '@app/_services/infolink.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-info-edit',
  templateUrl: './info-edit.component.html',
  styleUrls: ['./info-edit.component.scss'],
})
export class InfoEditComponent implements OnInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  @Input() baseFilter?: string;
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InfoLinkDto, number>;

  public infoLinks!: InfoLinkDto[];

  constructor(
    private infoLinkService: InfoLinkService,
    public accountService: AccountService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
  ) {}

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'Info.AddInfoLink',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'id',
      header: 'Global.Id',
      searchType: 'Text',
      flex: '0 0 75px',
    },
    {
      columnProperty: 'name',
      header: 'Global.Name',
      searchType: 'Text',
      displayFunction: (element: string, row: InfoLinkDto) =>
        row.translateName ? this.translate.instant(element) : element,
    },
    {
      columnProperty: 'link',
      header: 'Info.LinkHeader',
      searchType: 'Text',
    },
    {
      columnProperty: 'linkAction',
      header: 'Info.LinkActionHeader',
      valueMap: InfoLinkLinkActionTransformPipe.infoLinkLinkActionMap,
      displayTranslate: true,
      flex: '0 0 150px',
    },
    {
      columnProperty: 'isPublic',
      header: 'Info.IsPublic',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 85px',
    },
    {
      columnProperty: 'translateName',
      header: 'Info.TranslateName',
      searchType: 'Selection',
      valueMap: BooleanTransformPipe.booleanMap,
      displayTranslate: true,
      flex: '0 0 170px',
    },
    {
      columnProperty: 'order',
      header: 'Info.Order',
      searchType: 'Text',
      flex: '0 0 150px',
    },
  ];

  public tableSettings = <TableSettings<InfoLinkDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<InfoLinkDto, number>(this.infoLinkService, this.errorHandler),
    dataService: this.infoLinkService,
    tableColumns: this.tableColumns,
    baseFilter: this.baseFilter,
    baseColumnSort: [{ column: 'order', direction: 'asc' }],
    enableRowSelection: true,
    rowActions: this.rowActions,
    tableActions: this.tableActions,
    rowEditContainer: EditInfoLinkContainerComponent,
    rowEditContainerStyle: 'modal-1400',
    addRowTitle: 'Info.AddInfoLink',
    editRowTitle: 'Info.EditInfoLink',
    deleteRowTitle: 'Info.DeleteInfoLink',
    deleteRowText: 'Info.DeleteInfoLinkText',
  };

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    this.loadData();
  }

  loadData(): void {
    this.infoLinkService.getInfoLinksForUser().subscribe(
      (result) => {
        this.infoLinks = result;
        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }
}
