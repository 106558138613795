import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditInstitutionContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-institution-container/edit-institution-container.component';
import { InstitutionDto } from '@app/_models/institutionDto';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss'],
})
export class InstitutionsComponent implements AfterViewInit, RefreshableView {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InstitutionDto, number>;

  constructor(
    private institutionService: InstitutionService,
    public modalService: BsModalService,
    private errorHandler: ErrorHandlerService,
  ) {}

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'Institution.AddInstitution',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'id',
      header: 'Global.Id',
      searchType: 'Text',
      flex: '0 0 75px',
    },
    {
      columnProperty: 'zsrNumber',
      header: 'Global.ZsrNumber',
      searchType: 'Text',
      flex: '0 0 125px',
    },
    {
      columnProperty: 'title',
      header: 'Global.Title',
      searchType: 'Text',
    },
  ];

  public tableSettings = <TableSettings<InstitutionDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<InstitutionDto, number>(this.institutionService, this.errorHandler),
    dataService: this.institutionService,
    tableColumns: this.tableColumns,
    rowActions: this.rowActions,
    tableActions: this.tableActions,
    rowEditContainer: EditInstitutionContainerComponent,
    rowEditContainerStyle: 'modal-800',
    addRowTitle: 'Institution.AddInstitution',
    editRowTitle: 'Institution.EditInstitution',
    deleteRowTitle: 'Institution.DeleteInstitution',
    deleteRowText: 'Institution.DeleteInstitutionText',
  };

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }
}
