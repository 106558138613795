<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="container container-plus">
  <div class="page-header">
    <h1>{{ 'Admin.Info.Header' | translate }}</h1>
  </div>
  <form
    *ngIf="!(loading$ | async)"
    class="mt-lg-3"
    autocomplete="off"
    [formGroup]="applicationStatusForm"
    (ngSubmit)="saveApplicationStatus()">
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.SmsCredits' | translate }}
        </label>
      </div>
      <div class="col-sm-9 col-form-label pr-0">
        <label class="mb-0">
          {{ adminInfo.smsCredits }}
        </label>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.ApplicationLockoutEnabled' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <input type="checkbox" formControlName="userLockoutEnabled" class="form-control" style="margin-top: 10px" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.LockoutText' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <textarea
          class="form-control col-md-6 col-lg-4"
          maxlength="1000"
          formControlName="lockoutText"
          placeholder="{{ 'Admin.Info.LockoutText' | translate }}"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.DisplayWarningMessage' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <input type="checkbox" formControlName="displayWarningMessage" class="form-control" style="margin-top: 10px" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.WarningMessage' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <textarea
          class="form-control col-md-6 col-lg-4"
          maxlength="1000"
          formControlName="warningMessage"
          placeholder="{{ 'Admin.Info.WarningMessage' | translate }}"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label class="mb-0">
          {{ 'Admin.Info.InvoiceInfoDialogPage' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <input
          class="form-control col-md-6 col-lg-4"
          maxlength="1000"
          formControlName="invoiceInfoDialogPage"
          placeholder="{{ 'Admin.Info.InvoiceInfoDialogPage' | translate }}" />
      </div>
    </div>
    <div class="mt-5 border-t-1 bgc-secondary-l4 brc-secondary-l2 py-35 mx-n25">
      <div class="offset-md-3 col-md-9 text-nowrap">
        <button class="btn btn-primary btn-bold px-4" [disabled]="applicationStatusForm.invalid" type="submit">
          <i class="fa fa-check mr-1"></i>
          {{ 'Global.Save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
