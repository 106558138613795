import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { round } from 'lodash-es';
import { BehaviorSubject, zip } from 'rxjs';

import { CustomRowActionParams } from '@app/_controls/data-table/actions/customRowActionParams';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { InvoiceControllingRowDetailsComponent } from '@app/_controls/row-detail-views/invoice-controlling-row-details/invoice-controlling-row-details.component';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { CustomFormDialogComponent } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.component';
import { CustomFormDialogConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.config';
import { PdfViewerDialogComponent } from '@app/_dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { InvoiceStatusTransformPipe } from '@app/_helpers/transform/invoice-status.transform';
import { InvoiceStatus } from '@app/_models/enums/invoiceStatus';
import { InvoiceDto } from '@app/_models/invoiceDto';
import { ContractService } from '@app/_services/contract.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InvoiceControllingService } from '@app/_services/invoiceControlling.service';
import { PatientService } from '@app/_services/patient.service';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-invoice-controlling',
  templateUrl: './invoice-controlling.component.html',
  styleUrls: ['./invoice-controlling.component.scss'],
})
export class InvoiceControllingComponent implements OnInit, AfterViewInit {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  private contractTitleMap: Map<number, string> = new Map<number, string>();

  public idTableColumn: TableColumn = {
    columnProperty: 'id',
    header: 'Global.Id',
    searchType: 'Text',
    flex: '0 0 75px',
  };

  public tableColumns: Array<TableColumn> = [
    this.idTableColumn,
    {
      columnProperty: 'email',
      searchProperty: 'user.email',
      searchType: 'Text',
      header: 'Global.EMail',
    },
    {
      columnProperty: 'firstName',
      searchProperty: 'user.firstName',
      searchType: 'Text',
      header: 'Global.FirstName',
    },
    {
      columnProperty: 'lastName',
      searchProperty: 'user.lastName',
      searchType: 'Text',
      header: 'Global.LastName',
    },
    {
      columnProperty: 'createDate',
      header: 'Global.InvoiceDate',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      flex: '0 0 150px',
    },
    {
      columnProperty: 'dateFrom',
      header: 'Global.HospitalizationFrom',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      flex: '0 0 165px',
    },
    {
      columnProperty: 'dateTo',
      header: 'Global.HospitalizationUntil',
      searchType: 'DateRange',
      displayFunction: (element: Date, row: InvoiceDto) => formatDateOnly(element),
      flex: '0 0 165px',
    },
    {
      columnProperty: 'status',
      header: 'Global.State',
      searchType: 'Selection',
      valueMap: InvoiceStatusTransformPipe.statusMap,
      displayTranslate: true,
      flex: '0 0 100px',
    },
    {
      columnProperty: 'contractId',
      header: 'Global.Contract',
      searchType: 'Selection',
      valueMap: this.contractTitleMap,
      flex: '0 0 250px',
    },
    {
      columnProperty: 'total',
      header: 'Global.TotalTaxPoints',
      searchType: 'Text',
      displayFunction: (element: number, row: InvoiceDto) => round(element, 2),
      flex: '0 0 150px',
    },
    {
      columnProperty: 'memo',
      header: 'Global.Memo',
      searchType: 'Text',
    },
  ];

  openInvoicePdfActionParams: CustomRowActionParams = {
    customFunction: this.openInvoicePdf.bind(this),
  };

  approveInvoiceActionParams: CustomRowActionParams = {
    customFunction: this.approveInvoice.bind(this),
  };

  declineInvoiceActionParams: CustomRowActionParams = {
    customFunction: this.declineInvoice.bind(this),
  };

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Custom,
      actionParams: this.openInvoicePdfActionParams,
      tooltip: 'Invoice.Controlling.ShowInvoice',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-file-pdf fa-lg',
    },
    {
      action: RowActionEnum.Custom,
      actionParams: this.approveInvoiceActionParams,
      hideCondition: (element: InvoiceDto) => {
        return element.status != InvoiceStatus.Sent;
      },
      tooltip: 'Invoice.Controlling.ApproveInvoice',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-thumbs-up fa-lg',
    },
    {
      action: RowActionEnum.Custom,
      actionParams: this.declineInvoiceActionParams,
      hideCondition: (element: InvoiceDto) => {
        return element.status != InvoiceStatus.Sent && element.status != InvoiceStatus.Approved;
      },
      tooltip: 'Invoice.Controlling.DeclineInvoice',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-thumbs-down fa-lg',
    },
  ];

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<InvoiceDto, number>;

  constructor(
    public invoiceControllingService: InvoiceControllingService,
    public patientService: PatientService,
    public contractService: ContractService,
    public route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private modalService: BsModalService,
    private oauthService: OAuthService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    var getAllContractTypes = this.contractService.getAllContractTypes();

    zip(getAllContractTypes).subscribe(
      (result) => {
        result[0].forEach((element) => {
          this.contractTitleMap.set(element.id, element.title);
        });

        this.loadingSubject.next(false);
        this.loadTable();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  loadTable(): void {
    let tableSettings = <TableSettings<InvoiceDto, number>>{
      dataSource: new BaseTableDataServiceDataSource<InvoiceDto, number>(
        this.invoiceControllingService,
        this.errorHandler,
      ),
      rowDetailsContainerType: InvoiceControllingRowDetailsComponent,
      tableColumns: this.tableColumns,
      rowActions: this.rowActions,
      baseColumnSort: [{ column: 'createDate', direction: 'desc' }],
    };

    this.dataTableComponent.tableSettings = tableSettings;

    // Subscribe for queryParam updates to get column filter.
    this.route.queryParams.subscribe((result) => {
      this.idTableColumn.searchValue = result.id ? result.id : '';

      this.dataTableComponent.applyColumnFilter(this.idTableColumn);
      this.dataTableComponent.loadData();
    });
  }

  openInvoicePdf(element: InvoiceDto): void {
    this.translate.get('Global.Invoice').subscribe((res) => {
      var title = `${res} ${element.id}`;
      var downloadUrl = `/api/invoiceControlling/pdf/${element.id}`;
      PdfViewerDialogComponent.showDialog(this.modalService, title, downloadUrl, this.oauthService.getAccessToken());
    });
  }

  approveInvoice(element: InvoiceDto): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'Invoice.Controlling.ApproveInvoice',
      'Invoice.Controlling.ApproveInvoiceDialogText',
      'Global.Ok',
      'Global.Cancel',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          this.loadingSubject.next(true);

          this.invoiceControllingService.approve(element.id).subscribe(
            (response: any) => {
              this.dataTableComponent.loadData();
              this.loadingSubject.next(false);
            },
            async (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
    });
  }

  declineInvoice(element: InvoiceDto): void {
    var customTextAreaDialogConfig = CustomFormDialogConfig.getDefaultTextareaConfig({
      dialogTitle: 'Invoice.Controlling.DeclineInvoice',
      dialogText: 'Invoice.Controlling.DeclineInvoiceDialogText',
      labelSize: 0,
      inputSize: 12,
      required: true,
      modalClass: 'modal-1000',
      maxlength: 4000,
      textareaRows: 10,
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, customTextAreaDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        this.loadingSubject.next(true);

        this.invoiceControllingService.decline(element.id, onCloseResult.data.text).subscribe(
          (response: any) => {
            this.dataTableComponent.loadData();
            this.loadingSubject.next(false);
          },
          async (errorResponse: HttpErrorResponse) => {
            this.loadingSubject.next(false);
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );
      }
    });
  }
}
