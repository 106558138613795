import { Component, OnInit, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, zip } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CustomFormDialogComponent } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.component';
import { CustomFormDialogConfig } from '@app/_dialogs/custom-form-dialog/custom-form-dialog.config';
import { SmsVerificationDialogComponent } from '@app/_dialogs/sms-verification-dialog/sms-verification-dialog.component';
import { TextDialogComponent } from '@app/_dialogs/textdialog/textdialog.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { ContractDateTransformPipe } from '@app/_helpers/transform/contract-date.transform';
import { ContractForUserDto } from '@app/_models/contractForUserDto';
import { EmployeeDto } from '@app/_models/employeeDto';
import { ContractStatusType } from '@app/_models/enums/contractStatusType';
import { ContractTerminationType } from '@app/_models/enums/contractTerminationType';
import { ContractType } from '@app/_models/enums/contractType';
import { UserContractDto } from '@app/_models/userContractDto';
import { UserContractsRequestDto } from '@app/_models/userContractsRequestDto';
import { UserProfileDto } from '@app/_models/userProfileDto';
import { ContractService } from '@app/_services/contract.service';
import { EmployeeService } from '@app/_services/employee.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { SmsService } from '@app/_services/sms.service';
import { UserService } from '@app/_services/user.service';
import { UserContractService } from '@app/_services/userContract.service';
import { RefreshableView } from '@app/_views/refreshable-view';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-user-contracts',
  templateUrl: './user-contracts.component.html',
  styleUrls: ['./user-contracts.component.scss'],
})
export class UserContractsComponent implements OnInit, RefreshableView {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  contractTypes!: Record<number, ContractForUserDto>;
  userContracts!: UserContractDto[];
  filteredUserContracts?: UserContractDto[];
  showInactiveUserContracts: boolean = false;
  employee?: EmployeeDto;
  userProfile?: UserProfileDto;
  userMustEnterMobile!: boolean;
  userId?: string;

  selectedOptions?: UserContractDto[];
  contractIds?: number[];

  firstname: string = '';
  lastname: string = '';

  public contractTerminationType: typeof ContractTerminationType = ContractTerminationType;

  @ViewChild('selectionList') selectionList!: MatSelectionList;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: BsModalService,
    private contractService: ContractService,
    private userContractService: UserContractService,
    private userService: UserService,
    private employeeService: EmployeeService,
    private smsService: SmsService,
    private errorHandler: ErrorHandlerService,
    private contractDateTransformPipe: ContractDateTransformPipe,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    this.loadData();

    if (this.oauthService.hasValidAccessToken()) {
      const decodedToken: any = new JwtHelperService().decodeToken(this.oauthService.getAccessToken());
      this.firstname = decodedToken.given_name;
      this.lastname = decodedToken.family_name;
    }
  }

  refreshView(): void {
    this.loadData();
  }

  showCheckbox(userContract: UserContractDto): boolean {
    // Show checkbox if contract can be applied (-1), terminated (1) or confirmed (4)
    return userContract.contractStatus == -1 || userContract.contractStatus == 1 || userContract.contractStatus == 4;
  }

  showContractDetails(userContract: UserContractDto): boolean {
    return userContract.contractStatus != 0 && userContract.contractStatus != 2;
  }

  canApplyForContract(contract: ContractForUserDto, userContracts: UserContractDto[]): boolean {
    if (!contract.isValid) {
      return false;
    }

    if (userContracts.length == 0) {
      return true;
    }

    if (userContracts.some((x) => x.isPending || x.isUserConfirmationPending || x.isActive)) {
      return false;
    }

    if (
      userContracts.some(
        (x) =>
          x.contractStatus == ContractStatusType.Declined ||
          (x.contractStatus == ContractStatusType.Terminated &&
            // the user can only apply again if the base contract has no end date,
            // or the previously terminated user contract isn't valid until the end of the base contract
            (!contract.validUntil || (x.validUntil && x.validUntil < contract.validUntil))) ||
          x.contractStatus == ContractStatusType.UserDeclined,
      )
    ) {
      return true;
    }

    return false;
  }

  loadData(): void {
    this.selectedOptions = undefined;
    this.loadingSubject.next(true);

    this.userId = this.route.snapshot.params.userId;

    var getUserContractTypes = this.contractService.getUserContractTypes(this.userId);
    var getUserContracts = this.userContractService.getUserContracts(this.userId);

    zip(getUserContractTypes, getUserContracts).subscribe(
      (result) => {
        this.userContracts = [];
        this.contractTypes = Object.assign({}, ...result[0].map((x) => ({ [x.id]: x })));

        result[0].forEach((currentContract) => {
          // InvoiceOnly contracts cannot be applied.
          if (currentContract.contractType == ContractType.InvoiceOnly) {
            return;
          }

          var currentUserContracts = result[1].filter((x) => x.contractId == currentContract.id);

          // If the user does not have this contract or if all contracts are expired or denied we add a fake userContract that the user can apply for.
          if (this.canApplyForContract(currentContract, currentUserContracts)) {
            var applyUserContract: UserContractDto = {
              contractId: currentContract.id,
              contractStatus: -1,
              isActive: false,
              isPending: false,
              isUserConfirmationPending: false,
              isExpired: false,
              IsExpirationPending: false,
            };
            currentUserContracts.push(applyUserContract);
          }

          this.userContracts.push(...currentUserContracts);
        });

        this.filterUserContracts();

        this.loadingSubject.next(false);
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
        this.loadingSubject.next(false);
      },
    );

    if (this.userId) {
      // Get the employee to display name in title
      this.employeeService.get(this.userId).subscribe(
        (result) => {
          this.employee = result;
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    } else {
      this.userService.getUserProfile().subscribe(
        (result) => {
          this.userProfile = result;
          this.userMustEnterMobile = !result.mobilePhoneNumber;
        },
        (errorResponse: HttpErrorResponse) => {
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  public filterUserContracts(): void {
    if (this.showInactiveUserContracts) {
      this.filteredUserContracts = this.userContracts;
    } else {
      this.filteredUserContracts = this.userContracts.filter(
        (x: UserContractDto) =>
          x.contractStatus != ContractStatusType.Declined &&
          (x.contractStatus != ContractStatusType.Terminated || (x.validUntil && x.validUntil > new Date())) &&
          x.contractStatus != ContractStatusType.UserDeclined &&
          x.contractStatus != ContractStatusType.Replaced,
      );
    }
  }

  public selectAll(): void {
    this.selectionList.options.forEach((item: MatListOption) => (item.selected = !item.disabled));
  }

  public acceptOrConfirmSelected(): void {
    if (!this.selectedOptions || this.selectedOptions.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    if (this.selectedOptions.some((x) => x.isActive)) {
      this.showSelectInactiveContractsOnlyDialog();
      return;
    }

    if (
      this.selectedOptions.some((x) => x.contractStatus == -1) &&
      this.selectedOptions.some((x) => x.isUserConfirmationPending)
    ) {
      this.showSelectPendingOrUserConfirmationPendingContractsOnlyDialog();
      return;
    }

    if (!this.userId && this.userMustEnterMobile) {
      this.showUserMustEnterMobileDialog();
      return;
    }

    this.contractIds = this.selectedOptions?.map((x) => x.contractId);

    if (this.selectedOptions.every((x) => x.contractStatus == -1)) {
      this.acceptSelected();
    } else if (this.selectedOptions.every((x) => x.isUserConfirmationPending)) {
      this.confirmSelected();
    }
  }

  public acceptSelected(): void {
    var applyUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
    };

    this.loadingSubject.next(true);

    // Check if all parent contracts are being applied or are already active or pending.
    this.userContractService.validateApplyUserContracts(applyUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);

        // Display warnings (if there are any)
        if (result.warnings.length > 0) {
          this.errorHandler.displayWarningDialog(result).then((modalRef) => {
            modalRef.content?.onClose.subscribe((onCloseResult) => {
              if (onCloseResult) {
                this.showAcceptContractsDialog();
              }
            });
          });
        } else {
          this.showAcceptContractsDialog();
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  public confirmSelected(): void {
    var confirmUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
    };

    this.loadingSubject.next(true);

    // Check if all parent contracts are being applied or are already active or pending.
    this.userContractService.validateConfirmUserContracts(confirmUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.showConfirmContractsDialog();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  public terminateOrDeclineSelected(): void {
    if (!this.selectedOptions || this.selectedOptions.length == 0) {
      this.showNoContractsSelectedDialog();
      return;
    }

    var firstOption = this.selectedOptions![0];

    // Contract status of all selected options must be the same and must be isActive or isUserConfirmationPending
    if (
      !this.selectedOptions.every((x) => x.contractStatus == firstOption.contractStatus) ||
      (!firstOption.isActive && !firstOption.isUserConfirmationPending)
    ) {
      this.showSelectActiveOrIsUserConfirmationPendingContractsOnlyDialog();
      return;
    }

    if (!this.userId && this.userMustEnterMobile) {
      this.showUserMustEnterMobileDialog();
      return;
    }

    this.contractIds = this.selectedOptions?.map((x) => x.contractId);

    var applyUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
    };

    this.loadingSubject.next(true);

    if (firstOption.isActive) {
      // Check if all parent contracts are being terminated or are already terminated.
      this.userContractService.validateTerminateUserContracts(applyUserContractDto).subscribe(
        (result) => {
          this.loadingSubject.next(false);
          this.showTerminateContractsDialog();
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
    if (firstOption.isUserConfirmationPending) {
      // Check if contracts can be declined.
      this.userContractService.validateUserDeclineUserContracts(applyUserContractDto).subscribe(
        (result) => {
          this.loadingSubject.next(false);
          this.showDeclineContractsDialog();
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.errorHandler.displayErrorDialog(errorResponse);
        },
      );
    }
  }

  showAcceptContractsDialog(): void {
    var selectedContracts = this.selectedOptions?.map((x) => this.contractTypes[x.contractId].title).join('<br />');

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.AcceptSelectedDialogTitle',
      'User.Contracts.AcceptSelectedDialogText',
      'Global.Ok',
      'Global.Cancel',
      selectedContracts,
      'modal-600',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          if (this.userId) {
            // No sms verification needed on employees
            this.applyUserContracts();
          } else {
            this.loadingSubject.next(true);
            this.smsService.sendVerificationSms().subscribe(
              (result) => {
                if (result.value) {
                  // The server returns the validation code if not all required settings are set to send an sms.
                  // This is used for debug purposes to save sms costs.
                  console.log(`Verification code: ${result.value}`);
                }
                this.loadingSubject.next(false);
                this.showSmsVerificationDialog((verificationCode: any) => this.applyUserContracts(verificationCode));
              },
              (errorResponse: HttpErrorResponse) => {
                this.loadingSubject.next(false);
                this.errorHandler.displayErrorDialog(errorResponse);
              },
            );
          }
        }
      });
    });
  }

  showConfirmContractsDialog(): void {
    var confirmContractsText = this.selectedOptions
      ?.map((x) => this.getConfimContractText(this.contractTypes[x.contractId], x))
      .join('<br />');

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.ConfirmSelectedDialogTitle',
      'User.Contracts.ConfirmSelectedDialogText',
      'Global.Ok',
      'Global.Cancel',
      confirmContractsText,
      'modal-600',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          if (this.userId) {
            // No sms verification needed on employees
            this.confirmUserContracts();
          } else {
            this.loadingSubject.next(true);
            this.smsService.sendVerificationSms().subscribe(
              (result) => {
                if (result.value) {
                  // The server returns the validation code if not all required settings are set to send an sms.
                  // This is used for debug purposes to save sms costs.
                  console.log(`Verification code: ${result.value}`);
                }
                this.loadingSubject.next(false);
                this.showSmsVerificationDialog((verificationCode: any) => this.confirmUserContracts(verificationCode));
              },
              (errorResponse: HttpErrorResponse) => {
                this.loadingSubject.next(false);
                this.errorHandler.displayErrorDialog(errorResponse);
              },
            );
          }
        }
      });
    });
  }

  getConfimContractText(contract: ContractForUserDto, userContract: UserContractDto): String {
    return this.translate.instant('User.Contracts.ConfirmSelectedDialogContractText', {
      Title: contract.title,
      VvgP1: userContract.factorP1 ? userContract.factorP1 : '---',
      VvgP2: userContract.factorP2 ? userContract.factorP2 : '---',
      FactorP1: userContract.overallFactorP1 ? userContract.overallFactorP1 : '---',
      FactorP2: userContract.overallFactorP2 ? userContract.overallFactorP2 : '---',
      IndividualFactor: userContract.individualCompensation?.value,
      ValidFrom: this.contractDateTransformPipe.transform(userContract.validFrom, 'dd.MM.yyyy'),
      ValidUntil: this.contractDateTransformPipe.transform(userContract.validUntil, 'dd.MM.yyyy'),
    });
  }

  showTerminateContractsDialog(): void {
    var terminateContractsText = this.selectedOptions
      ?.map((x) => this.getTerminateContractText(this.contractTypes[x.contractId], x))
      .join('<br />');

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.TerminateSelectedDialogTitle',
      'User.Contracts.TerminateSelectedDialogText',
      'Global.Ok',
      'Global.Cancel',
      terminateContractsText,
      'modal-600',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          if (this.userId) {
            var firstDefaultTerminationDate = new Date(
              this.selectedOptions![0].defaultTermination!.defaultTerminationDate,
            );
            var sameDefaultDate = this.selectedOptions!.every(
              (x) => x.defaultTermination!.defaultTerminationDate.getTime() == firstDefaultTerminationDate.getTime(),
            );

            // No sms verification needed on employees, but user can select custom termination date.
            this.showSelectTerminationDateDialog(sameDefaultDate, firstDefaultTerminationDate);
          } else {
            this.loadingSubject.next(true);
            this.smsService.sendVerificationSms().subscribe(
              (result) => {
                if (result.value) {
                  // The server returns the validation code if not all required settings are set to send an sms.
                  // This is used for debug purposes to save sms costs.
                  console.log(`Verification code: ${result.value}`);
                }
                this.loadingSubject.next(false);
                this.showSmsVerificationDialog((verificationCode: any) =>
                  this.terminateUserContracts(verificationCode),
                );
              },
              (errorResponse: HttpErrorResponse) => {
                this.loadingSubject.next(false);
                this.errorHandler.displayErrorDialog(errorResponse);
              },
            );
          }
        }
      });
    });
  }

  getTerminateContractText(contract: ContractForUserDto, userContract: UserContractDto): String {
    var contractText = '';

    if (userContract.defaultTermination?.withinWithdrawalPeriod) {
      contractText = this.translate.instant('User.Contracts.TerminateSelectedDialogContractWithinWithdrawalText', {
        WithdrawalPeriod: contract.withdrawalPeriod,
      });
    } else {
      contractText = this.translate.instant('User.Contracts.TerminateSelectedDialogContractOutsideWithdrawalText', {
        TerminationDate: formatDateOnly(userContract.defaultTermination?.defaultTerminationDate),
      });
    }

    return this.translate.instant('User.Contracts.TerminateSelectedDialogContractText', {
      Title: contract.title,
      Text: contractText,
    });
  }

  showDeclineContractsDialog(): void {
    var selectedContracts = this.selectedOptions?.map((x) => this.contractTypes[x.contractId].title).join('<br />');

    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.DeclineSelectedDialogTitle',
      'User.Contracts.DeclineSelectedDialogText',
      'Global.Ok',
      'Global.Cancel',
      selectedContracts,
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          if (this.userId) {
            // No sms verification needed on employees, but user can select custom termination date.
            this.declineUserContracts(undefined);
          } else {
            this.loadingSubject.next(true);
            this.smsService.sendVerificationSms().subscribe(
              (result) => {
                if (result.value) {
                  // The server returns the validation code if not all required settings are set to send an sms.
                  // This is used for debug purposes to save sms costs.
                  console.log(`Verification code: ${result.value}`);
                }
                this.loadingSubject.next(false);
                this.showSmsVerificationDialog((verificationCode: any) => this.declineUserContracts(verificationCode));
              },
              (errorResponse: HttpErrorResponse) => {
                this.loadingSubject.next(false);
                this.errorHandler.displayErrorDialog(errorResponse);
              },
            );
          }
        }
      });
    });
  }

  showSelectTerminationDateDialog(
    sameDefaultDate: boolean,
    defaultTerminationDate?: Date,
    verificationCode?: number,
  ): void {
    var dateDialogConfig = CustomFormDialogConfig.getDefaultDateConfig({
      dialogTitle: 'Insurance.UserContracts.SelectTerminationDateDialog.Title',
      dialogText: sameDefaultDate
        ? undefined
        : 'Insurance.UserContracts.SelectTerminationDateDialog.VariousTerminationDatesHint',
      fieldLabel: 'Insurance.UserContracts.SelectTerminationDateDialog.TerminationDateLabel',
      initialValue: sameDefaultDate ? defaultTerminationDate : undefined,
      required: true,
    });

    var modalRef = CustomFormDialogComponent.showDialog(this.modalService, dateDialogConfig);

    modalRef.content?.onClose.subscribe((onCloseResult) => {
      if (onCloseResult.confirm) {
        this.terminateUserContracts(verificationCode, onCloseResult.data.date);
      }
    });
  }

  showSmsVerificationDialog(successFunc: (verificationCode: number) => void): void {
    this.modalService
      .show(SmsVerificationDialogComponent, {
        ignoreBackdropClick: true,
      })
      .content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult) {
          var verificationCode = Number(onCloseResult);
          this.loadingSubject.next(true);
          this.smsService.verifySmsCode(verificationCode).subscribe(
            (result) => {
              this.loadingSubject.next(false);
              successFunc(verificationCode);
            },
            (errorResponse: HttpErrorResponse) => {
              this.loadingSubject.next(false);
              this.errorHandler.displayErrorDialog(errorResponse);
            },
          );
        }
      });
  }

  applyUserContracts(verificationCode?: number): void {
    var applyUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
      verificationCode: verificationCode,
    };

    this.loadingSubject.next(true);
    this.userContractService.applyUserContracts(applyUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.loadData();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        // We load the data anyways because there could be contracts that succeeded.
        this.loadData();
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  confirmUserContracts(verificationCode?: number): void {
    var confirmUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
      verificationCode: verificationCode,
    };

    this.loadingSubject.next(true);
    this.userContractService.confirmUserContracts(confirmUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.loadData();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        // We load the data anyways because there could be contracts that succeeded.
        this.loadData();
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  terminateUserContracts(verificationCode?: number, terminationDate?: Date): void {
    var terminateUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
      verificationCode: verificationCode,
      terminationDate: terminationDate,
    };

    this.loadingSubject.next(true);
    this.userContractService.terminateUserContracts(terminateUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.loadData();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        // We load the data anyways because there could be contracts that succeeded.
        this.loadData();
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  declineUserContracts(verificationCode?: number): void {
    var declineUserContractDto: UserContractsRequestDto = {
      userId: this.userId,
      contractIds: this.contractIds,
      verificationCode: verificationCode,
    };

    this.loadingSubject.next(true);
    this.userContractService.userDeclineUserContracts(declineUserContractDto).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        this.loadData();
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        // We load the data anyways because there could be contracts that succeeded.
        this.loadData();
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  getContractType(contractId: number): ContractForUserDto | undefined {
    if (this.contractTypes) {
      return this.contractTypes[contractId];
    }

    return undefined;
  }

  showUserMustEnterMobileDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.UserMustEnterMobileDialogTitle',
      'User.Contracts.Errors.UserMustEnterMobileDialogText',
      'Global.Ok',
      undefined,
    );
  }

  showNoContractsSelectedDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.NoContractsSelectedTitle',
      'User.Contracts.Errors.NoContractsSelectedText',
      'Global.Ok',
      undefined,
    );
  }

  showSelectInactiveContractsOnlyDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.SelectInactiveContractsOnlyTitle',
      'User.Contracts.Errors.SelectInactiveContractsOnlyText',
      'Global.Ok',
      undefined,
    );
  }

  showSelectPendingOrUserConfirmationPendingContractsOnlyDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.SelectPendingOrUserConfirmationPendingContractsOnlyTitle',
      'User.Contracts.Errors.SelectPendingOrUserConfirmationPendingContractsOnlyText',
      'Global.Ok',
      undefined,
    );
  }

  showSelectActiveOrIsUserConfirmationPendingContractsOnlyDialog(): void {
    TextDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'User.Contracts.Errors.SelectActiveOrIsUserConfirmationPendingContractsOnlyTitle',
      'User.Contracts.Errors.SelectActiveOrIsUserConfirmationPendingContractsOnlyText',
      'Global.Ok',
      undefined,
    );
  }
}
