import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { RowAction, RowActionEnum } from '@app/_controls/data-table/actions/rowAction';
import { TableAction, TableActionEnum } from '@app/_controls/data-table/actions/tableAction';
import { DataTableComponent } from '@app/_controls/data-table/data-table.component';
import { TableColumn } from '@app/_controls/data-table/settings/tableColumn';
import { TableSettings } from '@app/_controls/data-table/settings/tableSettings';
import { BaseTableDataServiceDataSource } from '@app/_datasources/baseTableDataService.datasource';
import { EditPatientContainerComponent } from '@app/_dialogs/row-edit-dialog/containers/edit-patient-container/edit-patient-container.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { PatientDto } from '@app/_models/patientDto';
import { ApplicationService } from '@app/_services/application.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { PatientService } from '@app/_services/patient.service';
import { RefreshableView } from '@app/_views/refreshable-view';

@Component({
  selector: 'app-user-patients',
  templateUrl: './user-patients.component.html',
  styleUrls: ['./user-patients.component.css'],
})
export class UserPatientsComponent implements OnInit, AfterViewInit, RefreshableView {
  public tableColumns: Array<TableColumn> = [
    {
      columnProperty: 'id',
      header: 'Global.Id',
      searchType: 'Text',
      flex: '0 0 75px',
    },
    {
      columnProperty: 'firstName',
      header: 'Global.FirstName',
      searchType: 'Text',
    },
    {
      columnProperty: 'lastName',
      header: 'Global.LastName',
      searchType: 'Text',
    },
    {
      columnProperty: 'dateOfBirth',
      header: 'Global.DateOfBirth',
      searchType: 'SingleDate',
      displayFunction: (element: Date, row: PatientDto) => formatDateOnly(element),
    },
  ];

  public tableActions: Array<TableAction> = [
    {
      action: TableActionEnum.Add,
      text: 'User.Patients.AddPatient',
      buttonClass: 'btn btn-success btn-sm mb-1',
    },
  ];

  public rowActions: Array<RowAction> = [
    {
      action: RowActionEnum.Delete,
      tooltip: 'Global.Delete',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'red fa fa-trash fa-lg',
    },
    {
      action: RowActionEnum.Edit,
      tooltip: 'Global.Edit',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'green fa fa-pencil-alt fa-lg',
    },
    {
      action: RowActionEnum.Custom,
      tooltip: 'Global.Invoices',
      buttonClass: 'btn btn-sm mb-1',
      iconClass: 'fa fa-file-lines fa-lg',
      actionParams: {
        customFunction: this.navigatePatientInvoices.bind(this),
      },
    },
  ];

  public tableSettings = <TableSettings<PatientDto, number>>{
    dataSource: new BaseTableDataServiceDataSource<PatientDto, number>(this.patientService, this.errorHandler),
    tableColumns: this.tableColumns,
    tableActions: this.tableActions,
    rowActions: this.rowActions,
    rowEditContainer: EditPatientContainerComponent,
    rowEditContainerStyle: 'modal-1000',
    addRowTitle: 'User.Patients.AddPatient',
    editRowTitle: 'User.Patients.EditPatient',
    deleteRowTitle: 'User.Patients.DeletePatient',
    deleteRowText: 'User.Patients.DeletePatientText',
    baseColumnSort: [{ column: 'id', direction: 'desc' }],
  };

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent<PatientDto, number>;

  constructor(
    public patientService: PatientService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandlerService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.applicationService.showInvoiceInfoDialog();
  }

  ngAfterViewInit(): void {
    this.dataTableComponent.loadData();
  }

  refreshView(): void {
    if (this.dataTableComponent) {
      this.dataTableComponent.loadData();
    }
  }

  navigatePatientInvoices(element: PatientDto) {
    this.router.navigate(['/user/invoices', element.id]);
  }
}
